import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M620 2174 c-98 -26 -186 -107 -212 -197 -41 -146 20 -290 154 -356
120 -60 255 -36 346 63 21 23 42 49 45 57 5 11 9 10 19 -6 31 -49 106 -111
153 -128 63 -22 147 -22 210 0 47 16 144 98 158 133 5 12 9 11 19 -5 30 -47
108 -111 154 -128 62 -22 146 -22 209 0 47 15 144 97 159 133 5 13 7 13 12 0
15 -36 111 -116 159 -133 63 -22 147 -22 210 0 70 23 146 101 175 178 90 242
-167 482 -400 373 -56 -26 -125 -87 -135 -119 -8 -24 -22 -24 -30 0 -10 34
-78 93 -134 119 -115 53 -247 26 -336 -69 -25 -26 -45 -53 -45 -59 0 -21 -16
-9 -34 25 -59 110 -236 161 -364 105 -48 -21 -142 -107 -142 -131 0 -20 -16
-7 -38 31 -29 50 -95 96 -168 115 -69 18 -73 18 -144 -1z m196 -80 c27 -16 62
-50 79 -74 25 -38 30 -56 33 -121 4 -78 -6 -114 -44 -164 -15 -20 -16 -20 -40
10 -13 17 -38 64 -55 105 -72 176 -97 220 -132 235 -19 8 -45 11 -58 8 l-24
-7 24 -6 c13 -3 33 -17 43 -30 18 -25 18 -26 -28 -130 -26 -58 -55 -113 -66
-122 -20 -18 -19 -18 22 -18 33 0 40 3 32 13 -7 8 -6 21 2 41 11 26 17 29 61
31 l50 2 30 -61 c19 -38 42 -68 62 -81 18 -11 32 -25 33 -32 0 -18 -89 -47
-145 -47 -102 0 -180 49 -221 139 -47 99 -20 214 67 288 53 46 87 57 165 54
48 -2 72 -9 110 -33z m519 13 c87 -45 135 -123 135 -219 -1 -167 -147 -279
-305 -234 -104 31 -175 126 -175 235 0 94 67 192 155 226 49 18 148 14 190 -8z
m530 4 c90 -41 145 -126 145 -226 0 -59 -29 -145 -48 -145 -28 0 -185 194
-178 218 3 10 27 39 52 65 l47 47 -31 0 c-20 0 -32 -5 -32 -13 0 -7 -21 -41
-46 -75 l-46 -62 55 -68 c75 -91 93 -109 129 -128 34 -18 34 -19 -37 -56 -51
-27 -134 -29 -197 -5 -59 23 -123 91 -138 149 -15 52 -8 144 14 185 41 80 127
133 216 133 32 0 72 -8 95 -19z m552 -4 c82 -43 133 -127 133 -222 0 -75 -24
-128 -77 -176 -122 -110 -316 -72 -384 76 -25 53 -24 147 0 201 24 53 83 109
135 128 50 19 149 15 193 -7z m-1728 -160 c5 -18 12 -40 16 -49 6 -15 1 -18
-34 -18 -23 0 -41 3 -41 6 0 19 35 102 42 98 4 -3 11 -19 17 -37z"/>
<path d="M1167 2059 c-10 -6 -23 -22 -28 -37 -14 -37 5 -75 54 -108 65 -42 87
-65 87 -93 0 -36 -11 -50 -46 -60 -38 -11 -88 8 -104 40 -8 13 -10 38 -6 63 6
34 5 39 -6 28 -7 -7 -13 -33 -13 -58 0 -38 5 -50 28 -70 41 -35 123 -34 165 1
67 56 48 120 -53 186 -50 32 -65 47 -65 66 0 46 83 51 109 6 9 -15 10 -13 11
15 l0 32 -57 0 c-32 0 -66 -5 -76 -11z"/>
<path d="M1680 2063 l-35 -4 3 -126 c2 -96 0 -128 -10 -134 -8 -5 12 -8 47 -8
33 1 52 3 43 6 -16 4 -18 19 -18 129 0 76 4 124 10 124 6 0 10 5 10 10 0 6 -3
9 -7 8 -5 -1 -24 -3 -43 -5z"/>
<path d="M2282 2038 c-23 -12 -43 -62 -33 -88 3 -9 8 -2 12 20 11 54 60 76
110 50 58 -32 67 -165 14 -220 -24 -25 -37 -30 -74 -30 -79 1 -115 47 -114
148 0 49 6 70 22 93 25 34 11 38 -30 8 -82 -58 -78 -180 7 -239 53 -36 154
-40 208 -8 57 34 79 69 80 127 1 56 -19 91 -74 129 -36 24 -90 28 -128 10z"/>
<path d="M853 1511 c-27 -23 -29 -31 -7 -31 13 0 15 -12 12 -75 l-3 -75 46 0
c41 0 51 4 78 35 17 20 33 46 37 60 9 35 -9 78 -39 95 -23 13 -28 13 -47 -5
-20 -18 -22 -18 -31 -2 -12 21 -18 21 -46 -2z m101 -43 c39 -60 24 -115 -27
-103 -25 7 -27 11 -27 59 0 29 3 56 7 59 13 13 33 7 47 -15z"/>
<path d="M1073 1505 l-28 -25 33 6 c36 7 41 -4 13 -30 -10 -9 -22 -37 -25 -61
-4 -24 -16 -55 -28 -70 -11 -14 -17 -29 -14 -32 8 -8 76 57 76 73 0 6 12 14
26 17 22 4 28 0 36 -24 11 -32 24 -35 53 -13 18 14 19 16 3 25 -9 5 -22 26
-29 47 -22 69 -49 112 -69 112 -10 0 -32 -11 -47 -25z m65 -70 c11 -23 10 -25
-12 -25 -19 0 -22 4 -18 25 2 14 8 25 12 25 4 0 12 -11 18 -25z"/>
<path d="M1229 1510 l-24 -21 33 6 32 7 0 -81 c0 -44 4 -83 10 -86 5 -3 22 2
37 12 22 14 24 19 10 21 -14 3 -17 15 -17 63 0 51 2 59 19 59 10 0 29 9 42 20
l24 19 -71 1 c-58 0 -76 -4 -95 -20z"/>
<path d="M1423 1509 c-41 -26 -41 -31 0 -24 30 6 34 4 50 -30 21 -45 21 -45
-4 -45 -18 0 -20 4 -14 30 4 18 3 30 -4 30 -17 0 -41 -43 -41 -75 0 -16 -9
-45 -21 -64 -11 -18 -18 -35 -16 -37 7 -7 67 55 67 69 0 9 12 17 29 19 23 3
30 0 39 -24 11 -31 27 -35 57 -13 19 15 19 15 -1 29 -11 8 -26 32 -32 53 -15
50 -48 103 -64 103 -7 0 -28 -10 -45 -21z"/>
<path d="M1699 1501 c-16 -16 -29 -34 -29 -40 0 -6 19 -23 43 -37 41 -26 57
-42 57 -56 0 -16 -31 -7 -51 13 l-21 21 -21 -20 c-17 -18 -18 -23 -7 -36 20
-25 82 -20 114 9 40 37 36 52 -26 98 -36 27 -49 47 -29 47 5 0 17 -7 26 -16
15 -15 17 -15 35 1 12 11 20 13 20 7 0 -7 6 -11 13 -9 8 1 13 -19 15 -68 2
-38 7 -74 12 -79 5 -5 24 3 43 18 l35 27 4 -22 c4 -29 18 -33 43 -13 14 11 16
18 8 21 -9 3 -13 29 -13 78 0 41 -4 77 -10 80 -5 3 -21 -2 -35 -12 -26 -19
-33 -35 -12 -30 8 1 12 -12 12 -42 0 -34 -5 -47 -22 -58 l-23 -15 0 68 c0 87
-10 106 -40 79 -20 -18 -22 -18 -31 -2 -17 29 -75 22 -110 -12z"/>
<path d="M2005 1509 c-15 -16 -17 -22 -6 -28 9 -6 12 -28 9 -80 l-3 -71 45 0
c37 0 52 6 78 30 38 34 38 59 3 76 l-24 12 22 13 c26 17 27 31 1 54 -19 17
-21 17 -46 0 -25 -16 -28 -16 -36 -1 -11 20 -18 20 -43 -5z m95 -31 c0 -14
-30 -48 -42 -48 -10 0 -11 44 -1 53 11 12 43 8 43 -5z m14 -90 c14 -23 5 -29
-32 -22 -23 5 -32 12 -32 26 0 15 6 19 26 16 15 -2 32 -11 38 -20z"/>
<path d="M515 1252 c-11 -11 -4 -22 15 -22 17 0 20 -7 20 -50 0 -38 4 -50 15
-50 11 0 15 12 15 50 0 38 4 50 15 50 8 0 15 6 15 13 0 9 -14 14 -45 14 -25 0
-47 -3 -50 -5z"/>
<path d="M630 1195 c0 -49 3 -65 14 -65 9 0 13 11 12 32 -1 23 4 33 17 36 14
3 17 -4 17 -32 0 -25 4 -36 15 -36 17 0 18 12 9 60 -6 28 -10 31 -36 28 -27
-4 -29 -2 -23 19 4 16 1 23 -10 23 -12 0 -15 -14 -15 -65z"/>
<path d="M890 1195 l0 -65 39 0 c21 0 44 6 50 14 12 14 5 46 -10 46 -5 0 -4 9
3 21 11 17 10 22 -6 35 -11 8 -32 14 -48 14 -28 0 -28 0 -28 -65z m58 22 c2
-12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m12 -52 c0 -9 -9 -15
-25 -15 -16 0 -25 6 -25 15 0 9 9 15 25 15 16 0 25 -6 25 -15z"/>
<path d="M1320 1196 l0 -66 38 0 c48 0 62 14 62 61 0 43 -15 58 -64 66 l-36 6
0 -67z m76 9 c7 -29 -10 -55 -37 -55 -15 0 -19 8 -19 40 0 36 3 40 25 40 18 0
26 -7 31 -25z"/>
<path d="M1758 1255 c-11 -11 24 -125 38 -125 7 0 19 19 25 43 l13 42 9 -42
c15 -66 40 -58 56 18 7 34 10 63 7 66 -10 10 -26 -9 -26 -32 0 -13 -3 -32 -7
-42 -5 -14 -10 -7 -18 26 -14 58 -31 63 -40 12 -9 -57 -22 -59 -29 -6 -7 42
-15 53 -28 40z"/>
<path d="M2030 1194 l0 -65 43 3 c42 3 42 3 42 43 0 38 -2 40 -31 43 -26 3
-31 7 -27 23 4 13 0 19 -11 19 -13 0 -16 -12 -16 -66z m65 -19 c0 -11 -7 -21
-16 -23 -18 -3 -33 23 -23 39 10 17 39 5 39 -16z"/>
<path d="M1202 1233 c-4 -10 -5 -36 -2 -58 4 -35 9 -40 33 -43 15 -2 27 1 27
7 0 6 -7 11 -15 11 -20 0 -20 46 1 54 8 3 12 10 9 16 -4 6 -11 8 -16 5 -5 -4
-9 1 -9 9 0 21 -17 20 -28 -1z"/>
<path d="M1552 1238 c-8 -10 -9 -29 -4 -59 6 -35 12 -45 30 -47 12 -2 22 1 22
7 0 6 -4 11 -9 11 -5 0 -11 11 -13 25 -4 17 -1 25 8 25 8 0 14 8 14 17 0 9 -3
13 -7 10 -3 -4 -11 0 -18 9 -9 14 -13 14 -23 2z"/>
<path d="M2283 1231 c-6 -10 -8 -22 -3 -27 5 -5 10 -22 12 -39 2 -22 9 -31 26
-33 12 -2 22 1 22 7 0 6 -7 11 -15 11 -10 0 -15 10 -15 30 0 17 4 29 9 28 5
-2 8 2 8 7 -1 6 -5 9 -9 8 -5 -2 -8 4 -8 12 0 21 -13 19 -27 -4z"/>
<path d="M763 1219 c-27 -10 -32 -59 -9 -79 18 -14 58 -10 64 8 3 9 -3 11 -21
7 -14 -4 -28 -2 -31 4 -5 7 4 11 23 11 24 0 31 4 31 20 0 25 -29 40 -57 29z"/>
<path d="M1128 1222 c-27 -5 -22 -41 7 -52 32 -12 32 -22 0 -18 -13 2 -24 -1
-25 -7 0 -5 15 -10 32 -10 42 0 51 30 14 46 -26 12 -27 33 -1 22 8 -3 15 -1
15 5 0 13 -20 19 -42 14z"/>
<path d="M1468 1223 c-28 -7 -21 -23 9 -23 30 0 21 -16 -12 -22 -12 -2 -20
-11 -20 -23 0 -17 7 -20 43 -22 36 -1 43 1 38 13 -3 9 -6 26 -6 39 0 26 -25
44 -52 38z"/>
<path d="M1638 1222 c-30 -6 -21 -30 8 -24 19 3 25 1 20 -7 -4 -6 -16 -11 -27
-11 -14 0 -19 -7 -19 -25 0 -22 4 -24 45 -23 25 0 45 4 45 9 0 5 -4 9 -10 9
-5 0 -10 15 -10 34 0 35 -16 46 -52 38z"/>
<path d="M1953 1219 c-27 -10 -38 -42 -23 -69 9 -16 19 -20 43 -18 18 2 31 8
29 13 -2 6 -14 9 -27 7 -14 -2 -25 1 -25 7 0 6 14 11 30 11 23 0 30 4 30 20 0
25 -29 40 -57 29z"/>
<path d="M2155 1216 c-23 -16 -14 -46 15 -46 11 0 20 -5 20 -11 0 -7 -10 -9
-25 -5 -19 5 -25 2 -25 -9 0 -12 8 -15 33 -13 43 4 52 42 12 52 -14 4 -23 11
-20 15 3 5 14 7 25 4 24 -6 27 12 4 21 -20 8 -17 8 -39 -8z"/>
<path d="M2368 1211 c-19 -16 -21 -23 -13 -47 9 -24 16 -29 43 -29 17 0 32 5
32 10 -1 6 -12 9 -25 7 -14 -2 -25 1 -25 7 0 6 14 11 31 11 25 0 30 3 27 21
-5 32 -42 42 -70 20z"/>
<path d="M1010 1199 c-9 -16 -9 -28 -1 -46 9 -20 17 -24 44 -21 17 2 31 8 29
13 -2 6 -14 9 -27 7 -14 -2 -25 1 -25 7 0 6 14 11 31 11 26 0 30 3 27 23 -4
32 -62 37 -78 6z"/>
<path d="M2230 1175 c0 -33 4 -45 15 -45 11 0 15 12 15 45 0 33 -4 45 -15 45
-11 0 -15 -12 -15 -45z"/>
<path d="M1340 1019 c0 -48 -1 -51 -14 -33 -9 12 -11 21 -4 25 7 4 6 9 -1 13
-15 10 -26 -23 -12 -37 14 -14 15 -30 1 -22 -5 3 -10 1 -10 -4 0 -6 11 -10 25
-9 14 1 25 6 25 10 0 4 8 4 18 1 15 -6 15 -5 3 11 -11 13 -11 20 -3 28 18 18
14 32 -4 17 -13 -11 -14 -9 -9 13 4 15 2 29 -4 32 -7 5 -11 -10 -11 -45z"/>
<path d="M1761 1006 c-1 -58 -1 -58 21 -44 30 20 34 38 10 45 -13 3 -22 15
-25 32 -2 14 -5 0 -6 -33z"/>
<path d="M1470 1036 c0 -8 -7 -16 -15 -20 -16 -6 -21 -56 -6 -56 5 0 7 8 4 19
-3 10 1 22 7 26 9 5 10 2 6 -12 -4 -12 -1 -25 9 -33 8 -7 15 -8 15 -3 0 11 29
11 46 1 6 -4 10 7 10 28 0 40 -25 47 -48 12 -14 -20 -14 -20 -10 15 3 23 0 37
-7 37 -6 0 -11 -6 -11 -14z"/>
<path d="M1566 1035 c-9 -25 -7 -83 3 -89 5 -3 11 15 13 40 4 49 -5 77 -16 49z"/>
<path d="M1394 1017 c-9 -25 0 -62 16 -62 21 0 25 49 6 64 -13 11 -17 11 -22
-2z"/>
<path d="M1663 1014 c-3 -8 -1 -20 6 -27 14 -14 15 -30 1 -22 -5 3 -10 1 -10
-4 0 -6 14 -9 33 -7 17 3 38 5 45 5 7 1 12 14 11 34 l-1 32 -11 -27 c-14 -36
-26 -36 -19 -2 3 19 1 25 -7 20 -6 -4 -11 -14 -11 -22 0 -8 -5 -12 -10 -9 -6
4 -8 15 -5 26 8 23 -13 26 -22 3z"/>
<path d="M1837 1023 c-10 -9 -8 -61 2 -67 5 -3 14 1 21 9 11 13 14 13 26 -2
12 -16 15 -16 24 -3 13 20 12 33 -4 56 -14 19 -15 19 -22 -1 -10 -26 -33 -49
-41 -41 -3 3 -1 17 5 31 11 23 4 34 -11 18z"/>
<path d="M1983 995 c-1 -19 3 -38 8 -41 5 -3 9 13 9 35 0 50 -16 55 -17 6z"/>
<path d="M1000 991 c0 -32 14 -48 26 -30 4 8 9 7 15 -2 12 -19 29 7 28 41 -1
23 -2 22 -8 -5 l-7 -30 -8 25 c-7 20 -9 22 -13 8 -2 -10 -8 -18 -13 -18 -4 0
-6 9 -3 20 3 11 0 20 -6 20 -6 0 -11 -13 -11 -29z"/>
<path d="M1130 995 c-2 -25 -3 -26 -7 -7 -2 12 -9 22 -14 22 -14 0 -11 -24 5
-46 13 -17 15 -17 21 -2 4 9 5 27 2 40 -4 20 -5 19 -7 -7z"/>
<path d="M1199 995 c-1 -30 -1 -30 -9 -5 -6 22 -8 23 -15 7 -5 -12 -2 -24 8
-34 15 -15 17 -14 28 11 10 20 10 29 1 38 -9 9 -12 5 -13 -17z"/>
<path d="M1244 996 c-7 -28 2 -49 18 -40 7 4 6 9 -1 14 -13 7 -4 40 10 40 4 0
6 -6 3 -14 -3 -8 -1 -23 5 -33 9 -15 10 -12 11 20 0 30 -4 37 -20 37 -12 0
-22 -9 -26 -24z"/>
<path d="M1604 996 c-7 -28 2 -49 18 -40 7 4 6 9 -1 14 -13 7 -4 40 10 40 4 0
6 -6 3 -14 -3 -8 -1 -23 5 -33 9 -15 10 -12 11 20 0 30 -4 37 -20 37 -12 0
-22 -9 -26 -24z"/>
<path d="M1928 988 c-3 -15 -1 -28 4 -28 4 0 8 9 8 19 0 11 5 23 11 27 8 5 10
-1 5 -19 -3 -14 -2 -29 4 -32 6 -4 10 8 10 29 0 28 -4 34 -19 33 -13 -1 -21
-10 -23 -29z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
